module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"mxb2qzf"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-EP1Q3EP14J","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false,"cookieFlags":"secure;samesite=none"},"googleTagManager":{"trackingId":"process.env.GATSBY_GA_ID","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer","cookieFlags":"secure;samesite=none"},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel","controlCookieName":"gdpr-marketing-enabled"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Girardo & Co","short_name":"gac","start_url":"/","background_color":"#C20122","theme_color":"#C20122","display":"standalone","icon":"static/favicon-256x256.png","icons":[{"src":"static/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"static/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"static/favicon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"static/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"static/favicon-160x160.png","sizes":"160x160","type":"image/png"},{"src":"static/favicon-196x196.png","sizes":"196x196","type":"image/png"},{"src":"static/favicon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"static/favicon-196x196.png","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"crossOrigin":"use-credentials","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-EP1Q3EP14J"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
